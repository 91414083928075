<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:48:11
 * @LastEditTime: 2023-04-11 16:52:42
 * @Descripttion: 【首页】通知详情
-->
<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
.notify-detail {
    @include innerPage;
    @include pageHead(-10px);
    .content{
		width: 100%;
		padding: 20px;
        height: calc(100vh - 280px);
		overflow: auto;
		.h2{
            font-size: 18px;
            font-weight: bold;
            color: #222222;
            line-height: 32px;
		}
		.p{
		    margin-top: 10px;
            font-size: 14px;
            color: #888888;
		}
		.u-content{
			padding-top: 16px;
			img,video{
				max-width: 100%;
			}
		}
		.like{
			position: fixed;
			padding: 0 26px;
			height: 80px;
			background: #FFFFFF;
			box-shadow: 0px 14px 40px -17px #B7D0DE;
			border-radius:50px;
			font-size: 24px;
			right: 32px;
			bottom: 132px;
			color: #888888;
			&.opt{
				color: #FF7C12;
			}
		}
	}
}
@media screen and (max-width: 1200px) {
  .bitmap {
    width: 140px !important;
  }
  .notify-detail .page-head{
	height: 130px !important;
  }
}
</style>

<template>
    <section class="notify-detail">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
            </div>
        </div>
        <div class="content">
			<div class="h2" v-if="info.plstu_mes_title">{{info.plstu_mes_title}}</div>
			<div class="flex_bet_align">
				<div class="flex_align">
					<div class="p mr-32" v-if="info.create_time">
						{{info.create_time}}
					</div>
				</div>
			</div>
			<div class="u-content" v-if="info.plstu_mes_content">
				<div v-html="info.plstu_mes_content"></div>
			</div>
		</div>
    </section>
</template>

<script>
import { plastumessage_info } from "@api/home"
export default {
    name: 'home_notifyDetail',
    data() {
        return {
            info: {}
        }
    },
    created() {
        this.getDetail(this.$route.params.id)
    },
    methods: {
        async getDetail(id) {
            let { data } = await plastumessage_info({plstu_mes_id:id});
            this.info = data;
        }
    },
}
</script>